import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import Changes from './components/Changes';
import Login from "./components/Login";
import axios from "axios";

export default function App() {
  const [token, setToken] = useState(null);
  const [navItems, setNavItems] = useState([])
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function authenticate() {
      const code = localStorage.getItem('code');
      if (code) {
        try {
          const res = await axios.get('https://worktime-recorder.lebogo.tk/login', { headers: { authorization: code } })
          const data = res.data;
          sessionStorage.setItem('token', data.token);
          setToken(data.token);
        } catch (err) {
          localStorage.clear()
          sessionStorage.clear()
        }
      }
      setShow(true);
    }


    if (token) {
      setNavItems([
        { link: '/', name: "Home" },
        { link: '/app', name: "Download", server: true, newTab: true },
        { link: '/changes', name: "Änderungen" },
      ])
      setShow(true);
    } else {
      const sessionToken = sessionStorage.getItem('token');
      if (sessionToken) {
        setToken(sessionToken);
        setShow(true);
      } else {
        authenticate();
        setNavItems([
          { link: '/app', name: "Download", server: true, newTab: true },
          { link: '/changes', name: "Änderungen" },
        ]);
      }
    }
    return () => { }
  }, [token])


  return (
    <BrowserRouter>
      <div className="App">
        <Navbar navItems={navItems} />
        <div className="container">
          {show && <Switch>
            {token && <Route exact path="/" ><Home /></Route>}
            <Route path="/changes"><Changes /></Route>
            <Route><Login setToken={setToken} /></Route>
          </Switch>}
        </div>
      </div>
    </BrowserRouter>
  );
}

