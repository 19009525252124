import { NavLink } from "react-router-dom"

export default function Navbar({ navItems }) {
    const items = navItems.map(item => {
        return (
            <li key={item.link}>
                {(item?.server && !item?.newTab) && <a href={item.link}>{item.name}</a>}
                {(item?.server && item?.newTab) && <a target='_blank' rel="noreferrer" href={item.link}>{item.name}</a>}
                {!item?.server && <NavLink to={item.link}>{item.name}</NavLink>}
            </li>
        )
    })

    return (
        <nav className="nav-wrapper">
            <ul className="center hide-on-med-and-down">
                {items}
            </ul>
        </nav>
    )
}