import axios from 'axios';
import { Component } from 'react';
import '../login.css';
import Logo from '../Logo.png';
import M from 'materialize-css';

export default class Login extends Component {

    state = {
        code: '',
        remember: false
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.get('/login', { headers: { authorization: this.state.code } })
            const data = res.data;
            sessionStorage.setItem('token', data.token);
            if (this.state.remember) localStorage.setItem('code', data.token.substr(0, 7));

            if (!data.token) throw new Error('Invalid Code')
            this.props.setToken(data.token);
            this.setState({ showError: false });

        } catch (err) {
            M.toast({ html: 'Ungültiger Code' });
        }

    }

    handleInput = (e) => {
        this.setState({ code: e.target.value })
    }


    handleRemember = (e) => {
        this.setState({ remember: e.target.checked })
    }

    render() {
        return (
            <div className="form-signin">
                <div className="row center" style={{ marginTop: 20 }}>
                    <img src={Logo} alt="Logo" width="150" />
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="row" style={{ marginTop: 20 }}></div>
                    <div className="row">
                        <div className="input-field">
                            <input value={this.state.code} onChange={this.handleInput} autoComplete="off" id="code_input" type="text" required minLength="7" maxLength="7" />
                            <label htmlFor="code_input">Code</label>
                        </div>

                        <label>
                            <input type="checkbox" className="filled-in" value="remember-me" onChange={this.handleRemember} />
                            <span>Eingeloggt bleiben</span>
                        </label>

                    </div>
                    <div className="row center">
                        <button className="waves-effect waves-light btn" type="submit">Einloggen</button>
                    </div>
                    {/* <div className="imgContainer">
                        <img className="mb-4" src={logo} alt="" width="200" />
                    </div>
                    <h1 className="h3 mb-3 fw-normal" style={{ textAlign: 'center' }}>Login</h1>

                    {this.state.showError && <div className="alert alert-error alert-dismissible" role="alert" id="error">
                        <strong>Fehler:</strong> {this.state.error}
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => { this.setState({ showError: false }) }}></button>
                    </div>}

                    <div className="form-floating">
                        <input type="text" maxLength="7" minLength="7" required className="form-control" onChange={this.handleInput} placeholder=" " />
                        <label>Code</label>
                    </div>

                    <div className="checkbox mt-2 mb-3">
                        <label>
                            <input type="checkbox" value="remember-me" onChange={this.handleRemember} /> Eingeloggt bleiben
                        </label>

                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="submit">Einloggen</button> */}
                </form>
            </div >
        )
    }
}