import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Home extends Component {
    state = {
        posts: [],
        users: []
    }
    render() {
        const { posts, users } = this.state;
        const postList = posts.length ? (
            posts.map(post => {
                return (
                    <div className="post card" key={post.id}>
                        <div className="card-content">
                            <span className="card-title">{post.title}</span>
                            <span className="card-title">By: {users[post.userId - 1].username}</span>
                            <p>{post.body}</p>
                            <Link to={'/' + post.id} className="right">Read more</Link>
                        </div>
                    </div>
                )
            })
        ) : (
            <div className="center">No posts yet</div>
        );

        return (
            <div className="container home">
                <h4 className="center">Home</h4>
                {postList}
            </div>
        )
    }
}

export default Home;