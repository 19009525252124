import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';

const Changes = () => {
    const [version, setVersion] = useState('0.0.0');
    const [changes, setChanges] = useState([]);
    useEffect(() => {
        async function getChanges() {
            try {
                const res = await axios.get('https://worktime-recorder.lebogo.tk/version');
                setVersion(res.data.version);
                setChanges(res.data.changelog.split("\n").map(entry => {
                    const e = entry.replace('* ', '');
                    return (
                        <li className="list-group-item" key={e}>{e}</li>
                    )
                }))
            } catch { }
        }
        getChanges()
        return () => { }
    }, [])

    return (
        <div className="container">
            <h4 className="center">Änderungen ({version})</h4>
            <ul className="list-group list-group-numbered">
                {changes}
            </ul>
        </div>
    )
}

export default Changes;